<template>
    <div>
    
        <!-- Media -->
        <b-media class="mb-2">
            <template #aside>
                        <b-avatar ref="previewEl" :src="userData.avatar" :text="avatarText(userData.first_name)" :variant="`light-${resolveUserRoleVariant(userData.role_name)}`" size="90px" rounded />
            </template>
             <h4 class="mb-1">
                    Company Balance: {{ currency }} {{ balance | formatNumber }}
             </h4>
         <div class="card-text">Company Bonus: {{ currency }} {{ bonus | formatNumber }} </div>
         <div class="card-text">User Balance: {{ currency }} {{ userData.balance | formatNumber }} </div>
         <div class="card-text">User Bonus: {{ currency }} {{ userData.bonus | formatNumber }} </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
        <b-alert v-height-fade.appear :show="dismissCountDown" dismissible fade variant="danger" class="mb-0" @dismiss-count-down="countDownChanged">
            <div class="alert-body">
                <span>{{errorMessage}}</span>
            </div>
        </b-alert>
        <b-row>
            <b-col cols="12" md="6">
                <b-form-group label="Balance" label-for="balance">
                    <b-form-input type="number" id="balance" v-model="userData.balance" />
                </b-form-group>
            </b-col>
             <b-col cols="12" md="6">
                    <b-form-group label="Bonus" label-for="bonus">
                        <b-form-input type="number" id="balance" v-model="userData.bonus" />
                    </b-form-group>
                </b-col>

        </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click="updateInformation" :block="$store.getters['app/currentBreakPoint'] === 'xs'" id="editUser">
        <b-spinner small v-show="isLoading" />
        Allocate Funds
    </b-button>
   
</div>
</template>

<script>
import {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BSpinner,
    BAlert,
} from 'bootstrap-vue'
import {
    avatarText
} from '@core/utils/filter'
import vSelect from 'vue-select'
import {
    useInputImageRenderer
} from '@core/comp-functions/forms/form-utils'
import {
    ref
} from '@vue/composition-api'
import useUsersList from '../users-list/useUsersList'
import $ from 'jquery'
import numeral from "numeral";
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    heightFade
} from '@core/directives/animations'
import Ripple from 'vue-ripple-directive'
export default {
    components: {
        BButton,
        BMedia,
        BAvatar,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BTable,
        BCard,
        BCardHeader,
        BCardTitle,
        BFormCheckbox,
        vSelect,
        BSpinner,
        BAlert,
    },
    props: {
        userData: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const {
            resolveUserRoleVariant
        } = useUsersList()

        // ? Demo Purpose => Update image on click of update
        const refInputEl = ref(null)
        const previewEl = ref(null)

        const {
            inputImageRenderer
        } = useInputImageRenderer(refInputEl, base64 => {
            props.userData.avatar = base64
        })

        return {
            resolveUserRoleVariant,
            avatarText,
            refInputEl,
            previewEl,
            inputImageRenderer,
        }
    },
    directives: {
        heightFade,
        Ripple,
    },
    data() {
        return {
            errorMessage: "",
            dismissSecs: 5,
            dismissCountDown: 0,
            balance: 0,
            bonus: 0,
            currency: "KES",
            showDismissibleAlert: false,
            isLoading: false,
            roleOptions: [],
            statusOptions: [{
                    label: 'Pending',
                    value: '0'
                },
                {
                    label: 'Active',
                    value: '1'
                },
                {
                    label: 'Disabled',
                    value: '2'
                },
            ]
        }
    },
    mounted() {
        this.getSummaryData()
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        getSummaryData() {
            let vm = this
            vm.isLoadingAccount = true

            $.get({
                url: store.state.rootUrl + 'account/v1/view/wallet',
                type: "get",
                async: true,
                data: {
                    clientId: vm.$cookies.get("clientData").clientId
                },
                crossDomain: true,
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                },
                success: function(response, status, jQxhr) {
                    vm.isLoadingAccount = false
                    $('#resetPWD').html(' Reset Password');
                    if (response.data.code != 200) {
                        vm.balanceCount = 0
                    } else {
                        let result = response.data.data;
                        vm.balance = result.balance
                        vm.bonus = result.bonus
                        vm.currency = result.currency

                    }

                },
                error: function(jQxhr, status, error) {
                    vm.isLoadingAccount = false
                    vm.rows = []
                    console.log(jQxhr.responseJSON.data.code)
                    if (jQxhr.responseJSON.data.code == 401 || jQxhr.responseJSON.data.code == 500 || jQxhr.responseJSON.data.code == 403 || jQxhr.responseJSON.data.code == 404) {
                        vm.$cookies.remove("token");
                        vm.$cookies.remove("clientData");
                        // Redirect to login page
                        vm.$router.push({ name: 'login' })
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: jQxhr.responseJSON.data.message,
                                icon: 'EditIcon',
                                variant: 'failed',
                            },
                        })
                    }

                }
            });

        },
        updateInformation() {
             let vm = this;
            console.log("User data List " + JSON.stringify(vm.userData))
            vm.isLoading = true
            $('#editUser').html(' Please Wait ...');
            $.post({
                url: store.state.rootUrl + 'account/v1/allocate_balance',
                post: "POST",
                headers: {
                    "X-Authorization-Key": vm.$cookies.get("token"),
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-Type": "application/json",
                },
                data: JSON.stringify({
                    userMapId: vm.userData.user_mapId,
                    balance: vm.userData.balance,
                    bonus: vm.userData.bonus,
                }),
                success: function (response, status, jQxhr) {
                    vm.isLoading = false
                    $('#editUser').html(' Save Changes');
                    if (response.data.code != 200) {
                        vm.dismissCountDown = vm.dismissSecs
                        vm.showDismissibleAlert = true
                        vm.errorMessage = response.data.message

                    } else {
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: response.data.message,
                                icon: 'EditIcon',
                                variant: 'success',
                            },
                        })
                    }

                },
                error: function (jQxhr, status, error) {
                    vm.isLoading = false
                    vm.dismissCountDown = vm.dismissSecs
                    $('#editUser').html(' Save Changes');
                    var statustext = jQxhr.responseJSON.statusDescription;
                    vm.showDismissibleAlert = true;
                    if (!statustext) {
                        vm.errorMessage = "An error occurred. Try again later.";
                    } else {
                        vm.errorMessage = statustext + " " + errorMessage;
                    }
                }
            });
        }


    },
    filters: {
        formatNumber(value) {
            if (!value) return 0
            return numeral(value).format("0,0");
        },

    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
